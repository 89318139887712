import React from 'react';
import {withOktaAuth} from '@okta/okta-react';
import {useLegacyTelematicsDeviceContext} from '../../stores/LegacyTelematicsDevice/LegacyTelematicsDeviceProvider';
import {OutlinedInput} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormHelperText from '@material-ui/core/FormHelperText';
import {useDeviceAccessControlContext} from '../../stores/DeviceAccessControl/DeviceAccessControlProvider';
import {useAccessibilityContext} from "../../stores/AccessibilityProvider/AccessibilityProvider";
import {useDeviceContext} from "../../stores/TelematicsDevice/TelematicsDeviceProvider";

export enum AccessControlMode {
    useAccessKeys = 'USE_ACCESS_KEYS',
    unlockedForEveryone = 'UNLOCKED_FOR_EVERYONE',
    lockedForEveryone = 'LOCKED_FOR_EVERYONE',
    doNotUseAccessKeys = 'DO_NOT_USE_ACCESS_KEYS'
}

const DeviceAccessControl: React.FC = () => {
    const TLV_MIN_FW_VERSION = 62.16;

    const { telematicsDevice, isFirmwareVersionEqualOrHigher } = useLegacyTelematicsDeviceContext();
    const { mode, setMode } = useDeviceAccessControlContext();
    const accessControlModes: Array<AccessControlMode> = [
        AccessControlMode.useAccessKeys,
        AccessControlMode.unlockedForEveryone,
        AccessControlMode.lockedForEveryone
    ];
    const { accessibility } = useAccessibilityContext();
    const { state} = useDeviceContext();

    if (isFirmwareVersionEqualOrHigher(TLV_MIN_FW_VERSION) || (telematicsDevice?.accessControlMode !== undefined && telematicsDevice?.accessControlMode === AccessControlMode.doNotUseAccessKeys)) {
        accessControlModes.push(AccessControlMode.doNotUseAccessKeys);
    }

    React.useEffect(() => {
        if (telematicsDevice?.accessControlMode !== undefined) {
            setMode(telematicsDevice?.accessControlMode as AccessControlMode);
        }
    }, [telematicsDevice, setMode]);

    const onSelectAccessControl = (accessControlMode: AccessControlMode) => {
        setMode(accessControlMode)
    };

    return (
        <>
            {telematicsDevice !== undefined && (
                <>
                {accessibility?.classicAccessControl.visible ? 
                <FormControl variant="outlined" fullWidth margin="normal">
                    <dl key={'value_label_aux'} className="staticSection">
                        <dt>Access Control:</dt>
                        <dd>
                            {state.reported.accessControlDevice?.type ? state.reported.accessControlDevice?.type : "NO_ACCESSORY_CONNECTED"}
                        </dd>
                    </dl>
                    <FormHelperText>Changes will be visible after device reboot</FormHelperText>
                </FormControl>
                : null}

                    {telematicsDevice?.accessControlMode && accessibility?.classicAccessControl.visible && (
                    <FormControl
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        disabled={accessibility?.classicAccessControl.readonly}
                    >
                        <InputLabel htmlFor="access-control-modes">Access Control Mode</InputLabel>
                        {accessControlModes && (
                            <Select
                                value={mode || ''}
                                input={
                                    <OutlinedInput
                                        labelWidth={157}
                                        name="accessControlModes"
                                        id="access-control-modes"
                                    />
                                }
                                onChange={(evt: any) => onSelectAccessControl(evt.target.value)}
                            >
                                {accessControlModes.map(profile => (
                                    <MenuItem key={profile} value={profile}>
                                        {profile}
                                    </MenuItem>
                                ))}
                            </Select>
                        )}
                        {accessibility?.classicAccessControl.readonly && (
                            <FormHelperText>Can only be changed by owner</FormHelperText>
                        )}
                    </FormControl>
                    )}
            </>)}
       </>);
};

export default withOktaAuth(DeviceAccessControl);
